











































import { Component, Vue } from "vue-property-decorator";
import CONFIG from "../../config.js";
import "./effect.js";

@Component({
  components: {},
})
export default class Controller extends Vue {
  websock: any;
  buttonState = 0;
  btnStartDisabled = false;
  btnStopDisabled = true;
  canStop: false;
  coolDown = 3;
  timer: any;
  
  created() {
    if(!CONFIG.WS_ENABELD)return;
    this.initWebSocket(); //初始化连接
  }

  destroyed() {
    if(!CONFIG.WS_ENABELD)return;
    this.websock.close(); //离开路由之后断开websocket连接
  }

  mounted() {
    let obj=document.querySelector('canvas');
    let obj1=document.querySelector('.page-wrap');
    obj1.appendChild(obj);

    if(!CONFIG.WS_ENABELD)return;
    setInterval(this.websocketHeartbeat, 30000); //维持心跳
  }

  btnStartClick(ev) {
    console.log("btnClick->", ev);
    if(this.buttonState==0){ //点击开始
      this.buttonState=1; 
      this.btnStartDisabled=true;
      this.canStop=false;//需要由客户端返回可停止的消息
      this.timer=setInterval(this.incNum,1000);

      if(!CONFIG.WS_ENABELD)return;
      this.websocketsend(JSON.stringify({ action: "cmd-start", param: "" }));
    }
  }

  incNum() {
    this.coolDown--;
    if(this.coolDown==0){
      clearInterval(this.timer);
      if(!this.btnStopDisabled){
        this.buttonState=2;
      }else{
        this.buttonState=0;
        this.btnStartDisabled=false;
        this.coolDown=3;
      }
    }
  }

  btnStopClick(ev) {
    console.log("btnClick->", ev);
    this.btnStopDisabled=true;
    //this.buttonState=0; TODO:这里需要等前端返回消息来控制
    //this.btnStartDisabled=false; 
    if(!CONFIG.WS_ENABELD)return;
    this.websocketsend(JSON.stringify({ action: "cmd-stop", param: "" }));
  }

  initWebSocket() { //初始化weosocket
    console.log('init websocket...');
    this.websock = new WebSocket(CONFIG.WS_URL);
    this.websock.onmessage = this.websocketonmessage;
    this.websock.onopen = this.websocketonopen;
    this.websock.onerror = this.websocketonerror;
    this.websock.onclose = this.websocketclose;
  }

  websocketHeartbeat() { //心跳包
    let actions = { action: "heartbeat", params: "" }; 
    this.websocketsend(JSON.stringify(actions));
  }

  websocketonerror() { //连接建立失败重连
    console.log('websocket error! ');
    this.initWebSocket();
  }

  websocketsend(Data) { //数据发送
    console.log('websocket send->',Data);
    this.websock.send(Data);
  }

  websocketclose(e) { //关闭
    console.log('websocket close. bye');
    console.log("断开连接", e);
  }

  websocketonopen() { //连接建立之后注册自己
    console.log('websocket init successful!');
    let actions = { action: "controller-connected", params: { id: "" } }; //注册控制端
    this.websocketsend(JSON.stringify(actions));
  }

  websocketonmessage(e) { //数据接收
    console.log('websocket on message->',e);
    const redata = JSON.parse(e.data);
    switch(redata.action){
      case 'cmd-start-successful': //被控端返馈开始成功
        if(this.buttonState>0){ 
          this.btnStopDisabled=false;
        }
        break;
      case 'cmd-stop-successful': //被控端返馈停止成功
        if(this.buttonState>0){
          this.buttonState=0;
          this.coolDown=3;
          this.btnStartDisabled=false;
          this.btnStopDisabled=true;
        }
        break;
      default:break;
    }

  }
}
