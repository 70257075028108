
































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Controller extends Vue {
  websock: any;
  created() {
    console.log('created->');
    this.initWebSocket();
  }
  
  destroyed() {
    console.log('destroyed->');
    this.websock.close(); //离开路由之后断开websocket连接
  }
  
  mounted() {
    //setInterval(this.websocketHeartbeat,30000); //维持心跳
  }

  btnClick() {
    alert('ok!');
  }

  btnToast() {
  }


  initWebSocket() {
    //初始化weosocket
    const wsuri = "ws://127.0.0.1:2346";
    this.websock = new WebSocket(wsuri);
    this.websock.onmessage = this.websocketonmessage;
    this.websock.onopen = this.websocketonopen;
    this.websock.onerror = this.websocketonerror;
    this.websock.onclose = this.websocketclose;
  }

  websocketonopen() {
    //连接建立之后执行send方法发送数据
    let actions = {'action':'terminus-connected','params':{'id':''}}; //注册被控端
    this.websocketsend(JSON.stringify(actions));
  }
  
  websocketHeartbeat(){
    //心跳包
    let actions = {'action':'heartbeat','params':''}; //注册控制端
    this.websocketsend(JSON.stringify(actions));
  }

  websocketonerror() {
    //连接建立失败重连
    this.initWebSocket();
  }

  websocketonmessage(e) {
    //数据接收
    const redata = JSON.parse(e.data);
    console.log('resonse data->',redata);
  }

  websocketsend(Data) {
    //数据发送
    this.websock.send(Data);
  }

  websocketclose(e) {
    //关闭
    console.log("断开连接", e);
  }
}
